import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  ProductSortKeys,
  SearchProductFragment,
  useGetProductsForProductCardLazyQuery,
  useSearchQuery,
} from "../../generated/storefront";
import { productSearchFilter } from "../../helpers/product";
import { Loader } from "../../components/Loader";
import { useAppState } from "../../state";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";
import ProductCard from "../../components/ProductCard";
import { searchArtists } from "../../services/Firebase";

const Search = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { selectedCountry, isMobileScreen } = useAppState();
  const [products, setProducts] = useState<SearchProductFragment[]>();
  const [serachParams] = useSearchParams();
  const searchQs = serachParams.get("qs") || "";
  const [getProducts] = useGetProductsForProductCardLazyQuery();
  const {
    data,
    loading: loadingProducts,
    fetchMore,
    refetch,
  } = useSearchQuery({
    variables: { query: searchQs, limit: 250, country: selectedCountry },
    fetchPolicy: "no-cache",
  });
  const hasMore = data?.search.pageInfo.hasNextPage || false;
  const afterCursor = data?.search.pageInfo.endCursor || "";

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loadingProducts
  );

  const getSearchItems = async () => {
    const searchedArtists = await searchArtists(searchQs);
    const artistIds = searchedArtists.map((artist) => artist.id);

    const { data: artistProductsData } = await getProducts({
      variables: {
        limit: 8,
        query: `vendor:${artistIds.join(",")}`,
        sortKey: ProductSortKeys.BestSelling,
        country: selectedCountry,
      },
    });

    const products = data?.search.nodes.filter(productSearchFilter) as SearchProductFragment[];
    if (products) {
      setProducts([
        ...products,
        ...(artistProductsData?.products.nodes.filter((node) => !products.find((product) => product.id === node.id)) || []),
      ]);
    }
  };

  useEffect(() => {
    getSearchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.search]);

  return (
    <>
      <Helmet>
        <title>{`Search results for ${searchQs} | GoodMood`}</title>
      </Helmet>
      <Box paddingX={{ xs: 0, md: 5 }} paddingY={{ xs: 3 }} ref={ref}>
        {products ? (
          <Stack gap={3} alignItems="center">
            <Typography variant="h3">
              Showing {products.length === 250 ? "250+" : products.length} results for "{searchQs}"
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
              {products.map((product) => (
                <Grid item xs={12 / 2} md={12 / 4} key={product.id}>
                  <ProductCard product={product} indent={isMobileScreen} refetch={refetch} />
                </Grid>
              ))}
            </Grid>
            {loadingProducts ? (
              <Loader />
            ) : hasMore ? (
              <Stack width="100%" alignItems="center" padding={5}>
                <Button
                  onClick={() =>
                    fetchMore({
                      variables: {
                        afterCursor,
                      },
                    })
                  }
                >
                  Load more
                </Button>
              </Stack>
            ) : null}
          </Stack>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
};

export default Search;
