import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { useAppState } from "../../state";
import { User } from "../../types/user";
import { Frame } from "../../types/product";
import { ProductSortKeys, useGetArtistProductsQuery } from "../../generated/storefront";

const ArtistProducts = ({ artist, frame }: { artist: User; frame: Frame }) => {
  const { selectedCountry, isMobileScreen } = useAppState();
  const { data, loading } = useGetArtistProductsQuery({
    variables: {
      limit: 6,
      query: `vendor:${artist.id}`,
      sortKey: ProductSortKeys.CreatedAt,
      country: selectedCountry,
    },
  });
  const products = data?.products.nodes;

  return (
    <div>
      <ScrollCarousel
        loading={loading}
        minHeight={isMobileScreen ? "85vw" : "35vw"}
        header={`${artist.firstName} ${artist.lastName}`}
        cta={{
          link: `/artists/${artist.permalink || artist.id}`,
          text: "View all",
        }}
      >
        {products?.map((product) => <ProductCard product={product} key={product.id} frame={frame} />) || []}
      </ScrollCarousel>
    </div>
  );
};

export default ArtistProducts;
