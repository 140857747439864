import styled from "styled-components";
import WebpImage from "react-image-webp";

const Image = styled(WebpImage)<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
`;

export default Image;
