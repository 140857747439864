import WebpImage from "react-image-webp";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Box } from "@mui/material";
import { getFixedDimensions, mmDimensions, mountingWidth } from "../../helpers/layout";
import { useAppState } from "../../state";
import { Frame, Ratio, RectangleSize, Size, SquareSize } from "../../types/product";
import { Wrapper } from "./styles";
import { getFrameImages } from "./helpers";
import { sizeToTShirtSize } from "../../helpers/product";
import LazyLoadImage from "../Image/LazyLoadImage";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  frame: Frame;
  size?: Size;
  mounted?: boolean;
  scale?: number;
  shadow?: boolean;
};

export const FrameImage = styled(WebpImage)<{ w: number; h: number }>`
  z-index: 1;
  width: ${(p) => p.w}px;
  height: ${(p) => p.h}px;
`;

const DynamicFrameMockUp = ({ image, size, frame, mounted = false, scale = 1, shadow = false }: Props) => {
  const { isMobileScreen } = useAppState();
  if (!image) return null;
  const isLandscape = image.height && image.width ? image.height < image.width : false;
  const ratio = image.width === image.height ? Ratio.Square : Ratio.Rectangle;
  const fixedSize =
    size ||
    match(ratio)
      .with(Ratio.Rectangle, () => RectangleSize.A1)
      .with(Ratio.Square, () => SquareSize["28X28"])
      .exhaustive();
  const frameImages = getFrameImages(ratio, frame, sizeToTShirtSize(fixedSize), isLandscape);
  const fixedDims = getFixedDimensions(fixedSize, isLandscape, isMobileScreen);
  const { width, height } = fixedDims;
  const sizetoCalcMountingPct = match(fixedSize)
    .with(RectangleSize.A3, () => RectangleSize.A2)
    .otherwise(() => fixedSize);
  const mountingWidthPct = (mountingWidth / mmDimensions[sizetoCalcMountingPct][isLandscape ? "height" : "width"]) * 100;
  const mountingHeightPct = (mountingWidth / mmDimensions[sizetoCalcMountingPct][isLandscape ? "width" : "height"]) * 100;
  const frameDifference = isMobileScreen ? 6 : 15;
  const imageWidth = width * scale;
  const imageHeight = height * scale;
  const frameWidth = (width + frameDifference) * scale;
  const frameHeight = (height + frameDifference) * scale;
  const widthStyle =
    frame === Frame.Unframed ? "100%" : mounted ? `calc(${imageWidth}px - ${mountingWidthPct * 2}%)` : `${imageWidth}px`;
  const heightStyle =
    frame === Frame.Unframed ? "100%" : mounted ? `calc(${imageHeight}px - ${mountingHeightPct * 2}%)` : `${imageHeight}px`;
  const shadowWidth = imageWidth * (mountingWidthPct / 100) * 0.08;

  return (
    <Wrapper
      imageLoaded
      mounted={mounted}
      shadow={shadow}
      fixedWidth={frameWidth}
      fixedHeight={frameHeight}
      style={{ pointerEvents: "none" }}
    >
      {frame !== Frame.Unframed && (
        <FrameImage src={frameImages.src} webp={frameImages.webp} w={frameWidth} h={frameHeight} alt="frame" />
      )}

      <Box
        width={widthStyle}
        height={heightStyle}
        position="absolute"
        top="50%"
        left="50%"
        boxShadow={frame === Frame.Unframed ? "none" : `inset 0 0 0 10px #F9F9F9, 0 0 0 ${shadowWidth}px rgba(0, 0, 0, 0.1)`}
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <LazyLoadImage src={image.src} alt="print" height="100%" width="100%" />
      </Box>
    </Wrapper>
  );
};

export default DynamicFrameMockUp;
