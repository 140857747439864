export enum EmailTags {
  Artist = "artist",
  HasProducts = "has_products",
}

export enum EventType {
  NotifyArtist = "NotifyArtist",
  ArtistFirstUpload = "ArtistFirstUpload",
  ArtistUpload = "ArtistUpload",
  ArtistWorkIsLive = "ArtistWorkIsLive",
}

export type ArtistInvoiceEmail = {
  to: { name: string; email: string } | { name: string; email: string }[];
  pdfUrl: string;
  invoiceNumber: number;
  sendInvoiceToXero: boolean;
  dynamicTemplateData: {
    month: string;
    year: string;
    artistName: string;
  };
};

export type CollaboratorRequestEmail = {
  to: { name?: string; email: string } | { name?: string; email: string }[];
  dynamicTemplateData: {
    wishlistId: string;
    wishlistName: string;
    customerName: string;
    link: string;
  };
};
