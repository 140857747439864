import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../services/Firebase";
import { useEffect, useState } from "react";
import { Ratio, TShirtSize, UploadState, tShirtSizeNumbers } from "../types/product";
import { getDimensionsForCanvas, getMaxSizeFromResolutionDimensions, getResolutionDimensions } from "../helpers/product";

const getMaxSizeForFile = (longSide: number, shortSide: number) => {
  const uploadedRatio = longSide / shortSide;
  if (uploadedRatio === 1) {
    return getMaxSizeFromResolutionDimensions(longSide, shortSide, Ratio.Square);
  }
  return getMaxSizeFromResolutionDimensions(longSide, shortSide, Ratio.Rectangle);
};

const useFirebaseImage = (setUploadState: (state: Partial<UploadState>) => void, maxSize: TShirtSize, selectedRatio: Ratio) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageUploadError, setImageUploadError] = useState("");
  const [imageUploadWarning, setImageUploadWarning] = useState("");
  const [isLandscape, setIsLandscape] = useState(false);
  const [sizeOfUpload, setSizeOfUpload] = useState<TShirtSize>();
  const [timedOut, setTimedOut] = useState(false);

  const uploadImageToFirebase = (file: File, artworkReference: string) => {
    const storageRef = ref(storage, `artwork/${artworkReference}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed", (snapshot) => {
      const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(percent);
    });

    uploadTask.on("state_changed", {
      complete: function () {
        getDownloadURL(storageRef).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setUploading(false);
        });
      },
    });
  };

  const uploadImage = (files: File[]) => {
    setUploading(true);
    setProgress(0);
    setImageUploadError("");
    setImageUploadWarning("");
    const timeout = setTimeout(() => setTimedOut(true), 30000);
    const file = files[0];
    const fileTypes = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (fileExtension && !fileTypes.includes(fileExtension)) {
      setUploading(false);
      return setImageUploadError("Incorrect file extension, must be either jpg or png");
    }

    const image = new Image();
    const fileReader = new FileReader();
    const artworkReference = new Date().getTime() + file.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const setImage = () => {
      image.src = fileReader.result as string;
    };

    fileReader.onload = setImage;
    fileReader.readAsDataURL(file);

    const imageLoaded = async () => {
      clearTimeout(timeout);
      const landscape = image.height < image.width;
      setIsLandscape(landscape);
      const longSide = landscape ? image.width : image.height;
      const shortSide = landscape ? image.height : image.width;
      const uploadedRatio = longSide / shortSide;
      const ratio = uploadedRatio === 1 ? Ratio.Square : Ratio.Rectangle;
      const { width, height } = getResolutionDimensions(maxSize, ratio);

      if (uploadedRatio !== 1 && (uploadedRatio < 1.4 || uploadedRatio > 1.4286)) {
        setUploading(false);
        return setImageUploadError(
          `Incorrect ratio, please ensure the file is ${height}px x ${width}px. Current file is ${longSide}px x ${shortSide}px.`
        );
      }

      const maxSizeForFile = getMaxSizeForFile(longSide, shortSide);
      if (!maxSizeForFile) {
        setUploading(false);
        return setImageUploadError("File is too small, please upload a higher resolution image");
      }
      setSizeOfUpload(maxSizeForFile);

      if (maxSize === TShirtSize.XLarge && ratio === Ratio.Square) {
        maxSize = TShirtSize.Large;
      }

      let selectedMaxSize = maxSize;

      if (tShirtSizeNumbers[maxSizeForFile] < tShirtSizeNumbers[maxSize]) {
        selectedMaxSize = maxSizeForFile;
        setImageUploadWarning(
          `File only big enough for ${maxSizeForFile}, please upload a higher resolution image if you wish to sell at ${maxSize}.`
        );
      }

      uploadImageToFirebase(file, artworkReference);

      const canvasId = ratio === Ratio.Square ? "canvasSquare" : landscape ? "canvasLandscape" : "canvasPortrait";
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement | null;
      const qualityCheckCanvas = document.getElementById("qualityCheckCanvas") as HTMLCanvasElement | null;

      if (canvas) {
        const { width: canvasWidth, height: canvasHeight } = getDimensionsForCanvas(ratio);

        const ctx = canvas.getContext("2d");
        if (ctx) {
          if (landscape) {
            ctx.drawImage(image, 0, 0, canvasHeight, canvasWidth);
          } else {
            ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
          }
        }
      }

      if (qualityCheckCanvas) {
        qualityCheckCanvas.width = image.width * 0.2;
        qualityCheckCanvas.height = image.height * 0.2;
        const qctx = qualityCheckCanvas.getContext("2d");

        if (qctx) {
          qctx.drawImage(image, 0, 0, image.width * 0.2, image.height * 0.2);
        }
      }

      const dataUrlLowQual = canvas?.toDataURL("image/jpeg", 1);
      canvas?.toBlob((blob) => {
        if (!blob) {
          return;
        }
        let file = new File([blob], artworkReference, { type: "image/jpeg" });
        const qualityCheckImageSrc = qualityCheckCanvas?.toDataURL("image/jpeg", 1);
        if (!dataUrlLowQual) return setImageUploadError("Image could not be loaded, please try again");
        const img = new Image();
        img.src = dataUrlLowQual;
        img.onload = () => {
          setUploadState({
            maxSize: selectedMaxSize,
            ratio,
            imageFile: file,
            image: { src: dataUrlLowQual, altText: file.name, width: img.width, height: img.height },
            qualityCheckImageSrc,
            imageName: artworkReference,
          });
        };
      }, "image/jpeg");
    };

    image.onload = imageLoaded;
    image.onerror = () => {
      setUploading(false);
      return setImageUploadError("Failed to load image, please try again");
    };
  };

  useEffect(() => {
    setImageUploadError("");
  }, [maxSize, selectedRatio]);

  useEffect(() => {
    if (uploading && timedOut && progress === 0) {
      setUploading(false);
      setTimedOut(false);
      setImageUploadError("Upload timed out, please try again");
    }
  }, [uploading, timedOut, progress]);

  return { uploadImage, uploading, progress, imageUploadError, imageUploadWarning, isLandscape, sizeOfUpload };
};

export default useFirebaseImage;
