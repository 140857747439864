import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button";
import { Form } from "../../../components/Layout";
import Checkbox from "../../../components/Checkbox";
import { colors } from "../../../theme";
import { useAppState } from "../../../state";
import ErrorMessage from "../../../components/Form/ErrorMessage";
import PasswordInput from "../../../components/Form/PasswordInput";
import useLoginCustomer from "./useLoginCustomer";

const CustomerLogin = () => {
  const { errors } = useAppState();
  const [step, setStep] = useState(1);
  const {
    onCustomerCheck,
    onCustomerLoginChange,
    onCustomerRegisterChange,
    loginCustomer,
    registerCustomer,
    loading,
    signInUserData,
    signUpUserData,
    signInErrors,
    signUpErrors,
    successMessage,
    onAcceptsMarketingChange,
    acceptsMarketing,
    newAccount,
  } = useLoginCustomer();

  const onCheckEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await onCustomerCheck();
    setStep(2);
  };

  return (
    <>
      <Helmet>
        <title>Customer Login | GoodMood</title>
        <meta name="description" content="Login or create a customer account on GoodMood" />
      </Helmet>
      <Stack paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 3, md: 5 }} height="70vh" justifyContent="center" alignItems="center">
        <Stack gap={1.5} width="100%" maxWidth={500}>
          {step === 1 && (
            <>
              <Stack gap={0.5}>
                <Typography variant="h2">Sign in to continue</Typography>
                <Typography variant="subtitle2">Sign in or register with your email address</Typography>
              </Stack>
              <Form onSubmit={onCheckEmail}>
                <Input placeholder="Email" id="email" name="email" onChange={onCustomerLoginChange} error={signInErrors.email} />
                <Button fullWidth loading={loading}>
                  Continue
                </Button>
              </Form>
            </>
          )}

          {step === 2 &&
            (signUpUserData.email ? (
              <>
                <Stack gap={0.5}>
                  <Typography variant="h2">Create your account</Typography>
                  <Typography variant="subtitle2">Registration is easy!</Typography>
                </Stack>
                <Form onSubmit={registerCustomer}>
                  <Stack gap={1} width="100%">
                    <Stack gap={1} direction="row">
                      <Input
                        placeholder="First name"
                        id="firstName"
                        name="firstName"
                        value={signUpUserData.firstName}
                        onChange={onCustomerRegisterChange}
                        error={signUpErrors.firstName}
                      />
                      <Input
                        placeholder="Last name"
                        id="lastName"
                        name="lastName"
                        value={signUpUserData.lastName}
                        onChange={onCustomerRegisterChange}
                        error={signUpErrors.lastName}
                      />
                    </Stack>
                    <Input
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={signUpUserData.email}
                      onChange={onCustomerRegisterChange}
                      error={errors?.signUp?.email || signUpErrors.email}
                    />
                    <PasswordInput
                      onChange={onCustomerRegisterChange}
                      error={errors?.signUp?.password || signUpErrors.password}
                    />
                  </Stack>

                  <Checkbox
                    name="acceptsMarketing"
                    onChange={onAcceptsMarketingChange}
                    defaultChecked={acceptsMarketing}
                    label={<Typography fontSize={14}>Sign me up for GoodNews and a mystery discount</Typography>}
                  />

                  {errors?.signUp?.general && <ErrorMessage errorMessage={errors.signUp.general} />}
                  <Button fullWidth loading={loading}>
                    Create
                  </Button>

                  <Typography variant="caption" align="center">
                    By signing up for an account you accept our Terms and Privacy Policy.
                  </Typography>

                  {successMessage && (
                    <Typography color={colors.green} textAlign="center">
                      {successMessage}
                    </Typography>
                  )}
                </Form>
              </>
            ) : (
              <>
                <Stack gap={0.5}>
                  <Typography variant="h2">
                    {newAccount
                      ? "Account created, please login"
                      : `Welcome back${signInUserData.firstName ? `, ${signInUserData.firstName}` : ""}!`}
                  </Typography>
                  <Typography variant="subtitle2">Enter your password below</Typography>
                </Stack>
                <Form onSubmit={loginCustomer}>
                  <Stack gap={1} width="100%">
                    <Input
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={signInUserData.email}
                      onChange={onCustomerLoginChange}
                      error={errors?.signIn?.email || signInErrors.email}
                    />
                    <PasswordInput onChange={onCustomerLoginChange} error={errors?.signIn?.password || signInErrors.password} />
                  </Stack>
                  <Button fullWidth loading={loading}>
                    Login
                  </Button>
                  <Stack alignItems="center" width="100%">
                    <Link to="/reset-password/customer">
                      <Button tertiary>Forgot password?</Button>
                    </Link>
                  </Stack>
                </Form>
              </>
            ))}
        </Stack>
      </Stack>
    </>
  );
};

export default CustomerLogin;
