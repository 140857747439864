import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMedia, useToggle } from "react-use";
import momentData from "moment-timezone/data/meta/latest.json";
import { media } from "../helpers/layout";
import { getMarketByGeography } from "../services/API";
import { PaperType, UploadState, Ratio, TShirtSize } from "../types/product";
import { User } from "../types/user";
import { Market } from "../types/localisation";
import { freeDeliveryCountries } from "./constants";
import { BasketFragment, CountryCode } from "../generated/storefront";

export type Errors = {
  signIn?: {
    email?: string;
    password?: string;
  };
  signUp?: {
    email?: string;
    paypalEmail?: string;
    password?: string;
    general?: string;
  };
  upload?: string;
};

export type AppState = {
  navHeight: number;
  navTransparent: boolean;
  setNavTransparent: (transparent: boolean) => void;
  bannerHeight: number;
  user?: User;
  countryOfUser: CountryCode;
  availableMarkets: Market[] | undefined;
  setAvailableMarkets: (country: Market[]) => void;
  nearestMarket: Market | undefined;
  loadNearestMarket: () => Promise<void>;
  selectedMarket: Market | undefined;
  selectedCountry: CountryCode;
  setSelectedCountry: (country: CountryCode) => void;
  basket?: BasketFragment;
  setBasket: (basket: BasketFragment) => void;
  uploadState: UploadState;
  setUploadState: (uploadState: Partial<UploadState>) => void;
  isMobileScreen: boolean;
  itemAdded: boolean;
  setItemAdded: (added: boolean) => void;
  setError: (error: Errors | null) => void;
  errors: Errors | null;
  inFreeDeliveryCountry: boolean;
  freeDeliveryThreshold: number;
  showCookieBanner: boolean;
  setShowCookieBanner: (show: boolean) => void;
  galleryWallBuilderIsOpen: boolean;
  toggleGalleryWallBuilderIsOpen: () => void;
  customerEmail: string;
  setCustomerEmail: (email: string) => void;
};

export const defaultUploadState: UploadState = {
  title: "",
  description: "",
  image: {
    src: "",
    altText: "",
    width: 0,
    height: 0,
  },
  qualityCheckImageSrc: "",
  imageName: "",
  price: 40,
  paperType: PaperType.Matte,
  maxSize: TShirtSize.XLarge,
  ratio: Ratio.Rectangle,
  confirmedRgb: false,
  keywords: [],
  gmTags: [],
};

const AppStateContext = createContext<AppState | null>(null);

const useAppStateImplementation = () => {
  const [availableMarkets, setAvailableMarkets] = useState<Market[]>();
  const [nearestMarket, setNearestMarket] = useState<Market>();
  const [navTransparent, setNavTransparent] = useState(false);
  const [basket, setBasket] = useState<BasketFragment>();
  const [galleryWallBuilderIsOpen, toggleGalleryWallBuilderIsOpen] = useToggle(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.Gb);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [uploadState, setUploadState] = useState<UploadState>(defaultUploadState);
  const [itemAdded, setItemAdded] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const isMobileScreen = useMedia(media.m);
  const bannerHeight = 40;
  const navHeight = (isMobileScreen ? 56 : 72) + bannerHeight;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const countryOfUser = useMemo(
    () => Object.values(momentData.countries).find((country) => country.zones.includes(timeZone))?.abbr || CountryCode.Gb,
    [timeZone]
  ) as CountryCode;

  const freeDeliveryCountry = freeDeliveryCountries.find((c) => c.countryCode === selectedCountry);
  const inFreeDeliveryCountry = Boolean(countryOfUser && freeDeliveryCountry);
  const freeDeliveryThreshold = freeDeliveryCountry?.minOrderValue || 0;
  const selectedMarket = availableMarkets?.find((m) => m.regions.nodes.some((r) => r.code === selectedCountry));
  const [showCookieBanner, setShowCookieBanner] = useState(localStorage.getItem("closedCookieBanner") !== "true");

  const loadNearestMarket = async () => {
    if (countryOfUser) {
      const market = await getMarketByGeography({ country: countryOfUser });
      setNearestMarket(market);
    }
  };

  const setUploadStateAction = (state: Partial<UploadState>) => {
    setUploadState({ ...uploadState, ...state });
  };

  const setError = async (error: Errors | null) => {
    if (error) {
      setErrors({ ...errors, ...error });
    } else {
      setErrors(null);
    }
  };

  useEffect(() => {
    if (!availableMarkets) return;
    if (basket?.buyerIdentity?.countryCode) {
      if (availableMarkets?.find((m) => m.regions.nodes.some((r) => r.code === basket.buyerIdentity.countryCode))) {
        return setSelectedCountry(basket.buyerIdentity.countryCode);
      }
    }
    if (nearestMarket?.regions.nodes[0]?.code) {
      return setSelectedCountry(nearestMarket.regions.nodes[0].code);
    }
  }, [basket, nearestMarket, availableMarkets]);

  return {
    navHeight,
    navTransparent,
    setNavTransparent,
    bannerHeight,
    basket,
    countryOfUser,
    availableMarkets,
    setAvailableMarkets,
    nearestMarket,
    loadNearestMarket,
    selectedMarket,
    selectedCountry,
    setSelectedCountry,
    setBasket,
    uploadState,
    setUploadState: setUploadStateAction,
    isMobileScreen,
    itemAdded,
    setItemAdded,
    setError,
    errors,
    inFreeDeliveryCountry,
    freeDeliveryThreshold,
    showCookieBanner,
    setShowCookieBanner,
    galleryWallBuilderIsOpen,
    toggleGalleryWallBuilderIsOpen,
    customerEmail,
    setCustomerEmail,
  };
};

export const AppStateProvider: React.FC = ({ children }) => {
  const state = useAppStateImplementation();

  return <AppStateContext.Provider value={state}> {children} </AppStateContext.Provider>;
};

export const useAppState = (): AppState => {
  const state = useContext(AppStateContext);
  if (!state) throw new Error("React context used outside provider");

  return state;
};
