import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import PageHeader from "../../components/PageHeader";
import giftGuideImage from "../../assets/images/giftguide.webp";
import { colors } from "../../theme";
import { ProductSortKeys, useGetProductsForProductCardQuery } from "../../generated/storefront";
import ScrollCarousel from "../../components/ScrollCarousel";
import { getIdNumber } from "../../helpers/shopify";
import { giftCardId, scrollbarStyles } from "../../state/constants";
import Button from "../../components/Button";
import giftCard from "../../assets/images/giftcard.png";
import { Image } from "../../components";
import { useAppState } from "../../state";
import ProductCard from "../../components/ProductCard";
import useGiftGuideCollections from "./useGiftGuideCollections";
import { Loader } from "../../components/Loader";

const GiftGuide = () => {
  const { selectedCountry, isMobileScreen } = useAppState();
  const {
    personalityCollections,
    loadingPersonalityCollections,
    zodiacCollections,
    loadingZodiacCollections,
    giftForCollections,
    loadingGiftForCollections,
    roomCollections,
    loadingRoomCollections,
    seasonCollections,
    loadingSeasonCollections,
    occasionCollections,
    loadingOccasionCollections,
  } = useGiftGuideCollections();
  const { data: communityFavouritesData, refetch: refetchCommunityFavourites } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: "tag:community_favourites",
      country: selectedCountry,
      limit: 20,
    },
  });
  const communityFavourites = communityFavouritesData?.products?.nodes || [];

  useEffect(() => {
    refetchCommunityFavourites({
      country: selectedCountry,
    });
  }, [selectedCountry, refetchCommunityFavourites]);

  return (
    <>
      <PageHeader
        header="Gift Guide"
        image={isMobileScreen ? "" : giftGuideImage}
        webpImage={isMobileScreen ? "" : giftGuideImage}
        background={colors.beige}
        imageLink="/products/8184651186390"
      >
        <Typography>Looking for something they actually want? Look no further.</Typography>
      </PageHeader>

      <Box paddingY={5}>
        <ScrollCarousel
          header="Gift by personality"
          loading={loadingPersonalityCollections}
          minHeight={isMobileScreen ? "40%" : "20%"}
          slideWidth={isMobileScreen ? "40%" : "20%"}
        >
          {personalityCollections?.map((collection) => (
            <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
              <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                <Image alt={collection.image?.altText || collection.title} src={collection.image?.url} />
                <Typography fontSize={{ xs: 14, md: 16 }}>{collection.title.split(": ").pop()}</Typography>
              </Stack>
            </Link>
          )) || []}
        </ScrollCarousel>
      </Box>

      <Box paddingY={5} bgcolor={colors.beige}>
        <Typography variant="h2" marginBottom={2} paddingX={{ xs: 2, md: 5 }}>
          Follow the stars: Zodiacs
        </Typography>
        {loadingZodiacCollections ? (
          <Loader />
        ) : (
          <Stack direction="row" gap={{ xs: 2, md: 3 }} paddingX={{ xs: 2, md: 5 }} overflow="auto" sx={scrollbarStyles}>
            {zodiacCollections?.map((collection) => (
              <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
                <Stack key={collection.id} gap={{ xs: 1, md: 1.5 }} alignItems="center">
                  <Image
                    src={collection.image?.url}
                    alt={collection.image?.altText || collection.title}
                    width={isMobileScreen ? "100px" : "180px"}
                    height={isMobileScreen ? "100px" : "180px"}
                  />
                  <Stack>
                    <Typography fontSize={{ xs: 14, md: 16 }} align="center">
                      {collection.title.split(": ").pop()}
                    </Typography>
                    {collection.subtext && (
                      <Typography fontSize={{ xs: 12, md: 16 }} color={colors.grey60} align="center">
                        {collection.subtext?.value}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Link>
            )) || []}
          </Stack>
        )}
      </Box>

      <Box paddingX={{ xs: 2, md: 5 }} paddingY={5}>
        <Typography variant="h2" marginBottom={2}>
          Gift for
        </Typography>
        {loadingGiftForCollections ? (
          <Loader />
        ) : (
          <Stack direction="row" gap={{ xs: 1, md: 2 }}>
            {giftForCollections?.map((collection) => (
              <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
                <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                  <Image alt={collection.image?.altText || collection.title} src={collection.image?.url} />
                  <Typography fontSize={{ xs: 14, md: 16 }} align="center">
                    {collection.title.split(": ").pop()}
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
        )}
      </Box>

      <Box paddingY={5} bgcolor={colors.beige}>
        <ScrollCarousel
          header="Gift for a room"
          loading={loadingRoomCollections}
          minHeight={isMobileScreen ? "40%" : "20%"}
          slideWidth={isMobileScreen ? "40%" : "20%"}
        >
          {roomCollections?.map((collection) => (
            <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
              <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                <Image alt={collection.image?.altText || collection.title} src={collection.image?.url} />
                <Typography fontSize={{ xs: 14, md: 16 }} align="center">
                  {collection.title.split(": ").pop()}
                </Typography>
              </Stack>
            </Link>
          )) || []}
        </ScrollCarousel>
      </Box>

      <Box paddingX={{ xs: 2, md: 5 }} paddingY={5}>
        <Typography variant="h2" marginBottom={2}>
          Occasions
        </Typography>
        {loadingOccasionCollections ? (
          <Loader />
        ) : (
          <Stack direction="row" gap={{ xs: 1, md: 2 }}>
            {occasionCollections?.map((collection) => (
              <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
                <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                  <Image src={collection.image?.url} alt={collection.image?.altText || collection.title} />
                  <Typography fontSize={{ xs: 14, md: 16 }} align="center">
                    {collection.title.split(": ").pop()}
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
        )}
      </Box>

      <Box style={{ justifyContent: "center" }}>
        <Link
          to={{
            pathname: `/products/${getIdNumber(giftCardId)}`,
          }}
          style={{ position: "relative" }}
        >
          <Stack direction={{ xs: "column", md: "row" }} alignItems="center" bgcolor={colors.beige}>
            <Box width={{ xs: "100%", md: "50%" }} paddingX={{ xs: 2, md: "6%" }} paddingY={{ xs: 4, md: 5 }}>
              <Stack gap={{ xs: 2, md: 4 }} alignItems="flex-start">
                <Stack gap={{ xs: 0.5, md: 1 }}>
                  <Typography variant="h1" component="h2" fontSize={{ xs: 28, md: 72 }}>
                    Can't decide?
                  </Typography>
                  <Typography fontSize={{ xs: 14, md: 16 }}>Give the gift of choice</Typography>
                </Stack>
                <Button secondary>Shop gift card</Button>
              </Stack>
            </Box>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Image src={giftCard} alt="gift card" width="100%" height="100%" />
            </Box>
          </Stack>
        </Link>
      </Box>

      <Box paddingX={{ xs: 2, md: 5 }} paddingY={5}>
        <Typography variant="h2" marginBottom={2}>
          Gift by season
        </Typography>
        {loadingSeasonCollections ? (
          <Loader />
        ) : (
          <Stack direction="row" gap={{ xs: 1, md: 2 }}>
            {seasonCollections?.map((collection) => (
              <Link to={`/shop/collections/${collection.handle}`} key={collection.id}>
                <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
                  <Image src={collection.image?.url} alt={collection.image?.altText || collection.title} />
                  <Typography fontSize={{ xs: 14, md: 16 }}>{collection.title.split(": ").pop()}</Typography>
                </Stack>
              </Link>
            )) || []}
          </Stack>
        )}
      </Box>

      {communityFavourites && (
        <Box paddingBottom={4}>
          <ScrollCarousel
            header="Community favourites"
            cta={{
              link: "/shop/collections/community-favourites",
              text: "View all",
            }}
          >
            {communityFavourites.map((product) => <ProductCard product={product} key={`community-picks-${product.id}`} />) || []}
          </ScrollCarousel>
        </Box>
      )}
    </>
  );
};

export default GiftGuide;
