import { Stack } from "@mui/material";
import Accordion from "../../components/Accordion";
import { Column, Container, Flex } from "../../components/Layout";
import { Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { deliveryCountries } from "../../state/constants";

const Buyer = () => {
  return (
    <Container padding="0 0 400px">
      <Flex justifyContent="center">
        <Column width={50}>
          <Accordion
            highlight
            items={[
              {
                label: "Where do you ship?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>We ship to most countries in the world as we have global fulfilment coverage.</Text>
                    <Text>We currently offer delivery to the following countries: {deliveryCountries.join(", ")}.</Text>
                  </Stack>
                ),
              },
              {
                label: "Will my order have customs taxes and import duty to pay?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Any orders within the UK, EU, US & Australia will not incur custom fees and duties. For all other
                      destinations, duty and custom fees are unlikely, however they may be incurred, and it is the responsibility
                      of the end customer to pay for this.
                    </Text>
                    <Text>
                      We have no control over these charges, so GoodMood cannot advise on specific orders and it is best to
                      contact your local custom authorities for more information. If your order incurs import duties and taxes in
                      your country, they must be paid directly to your tax authority or the courier, and then shipment will be
                      released to you.
                    </Text>
                    <Text>
                      If you do not pay and the delivery is returned to us we will need to recharge postage to send it back to
                      you.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How long does shipment take?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Typically our orders are dispatched within 2 working days. Therefore for orders in UK, EU, US and Australia
                      you should expect to receive orders within 5 working days. For orders to other parts of the world, please
                      allow up to 10 working days.
                    </Text>
                    <Text>We currently offer delivery to the following countries: {deliveryCountries.join(", ")}.</Text>
                    <Text>Factors which could delay delivery times include:</Text>
                    <ul style={{ margin: 0 }}>
                      <li>
                        <Text>Mistyped or incorrectly formatted address</Text>
                      </li>
                      <li>
                        <Text>Customs and import restrictions</Text>
                      </li>
                      <li>
                        <Text>Failed delivery attempts (i.e. no one home to receive the order)</Text>
                      </li>
                    </ul>
                    <Text>
                      If there is a long delay with your order then please contact us at{" "}
                      <TextLink href="mailto:hello@goodmoodprints.io<">hello@goodmoodprints.io</TextLink> and we will deal with
                      this as best we can
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What delivery or courier options are there?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We ship all of our orders using a tracked service and we use different courier services depending on where
                      you have ordered to.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do I track my order?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Tracking availability will depend on the country you have ordered from. However, typically you will receive
                      an email when your order has been dispatched, which will include tracking information. If you can’t find
                      tracking information, then please contact us on{" "}
                      <TextLink href="mailto:hello@goodmoodprints.io<">hello@goodmoodprints.io</TextLink>
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What happens if a product is damaged in transit?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      In the very rare event that a delivery is damaged, we ask for a photo of the damaged goods, and we’ll then
                      gladly sort a replacement. We will need to arrange a collection of the damaged goods and then arrange a
                      replacement to be delivered.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What payment methods do you accept?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We accept Visa, Mastercard, American Express, Discover, Diners Club, Maestro, Shop Pay, Apply Pay, Google
                      Pay and Paypal payments.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How much does delivery cost?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We charge £4.95 for prints and £9.95 for framed prints (or equivalent local price) to most countries.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "Can I change, cancel, edit or add to an order?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Please contact us on <TextLink href="mailto:hello@goodmoodprints.io<">hello@goodmoodprints.io</TextLink> as
                      quickly as possible if necessary. We will always look to change a delivery address where possible, however,
                      if the order has passed beyond the point where changes can be made, then the customer takes responsibility
                      for providing the incorrect address.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do I open and hang my print?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Visit our <TextLink to="/hanging-guide">Print Opening and Hanging Guide</TextLink> for a recommended step by
                      step process on how to open and hang your print
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What is your returns policy?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Please contact us by emailing{" "}
                      <TextLink href="mailto:hello@goodmoodprints.io<">hello@goodmoodprints.io</TextLink> within 30 days of
                      receiving an order and we will be happy to process a return and refund. Items will have to be returned to
                      our UK address. You will need to cover the cost of delivery, and we would recommend you send your return
                      with a tracked service. We will also not refund original delivery charges. The item must be returned in
                      original condition and in its original packaging. Once we receive the item, we will process a refund or
                      exchange.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What are your environmental credentials?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      The planet is incredibly important to us. We only use eco-inks and all of our paper, wood and packaging is
                      sustainably sourced.
                    </Text>
                    <Text>
                      We also print on demand as close to the delivery destination as possible. Printing on demand means there is
                      no wastage as every item is made to order. Printing and framing as locally as possible reduces transport
                      distances and therefore emissions.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do I contact GoodMood?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      You can email us at <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> and
                      we normally respond within 24 hours.
                    </Text>
                  </Stack>
                ),
              },
            ]}
          />
        </Column>
      </Flex>
    </Container>
  );
};

export default Buyer;
