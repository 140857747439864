import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { CollectionFragment } from "../../generated/storefront";

type Props = {
  collection: CollectionFragment;
};

const CollectionCarousel = ({ collection }: Props) => {
  const products = collection.products.nodes || [];

  return (
    <div style={{ minHeight: 375 }}>
      <ScrollCarousel
        header={collection.title.split(": ").pop()}
        cta={{ text: "View all", link: `/shop/collections/${collection.handle}` }}
      >
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </ScrollCarousel>
    </div>
  );
};

export default CollectionCarousel;
