import { Route, Routes } from "react-router";
import { ApolloProvider } from "@apollo/client";
import AuthGuard from "./components/AuthGuard";
import EditProduct from "./pages/Profile/EditProduct";
import ProductBreakdown from "./pages/Profile/ProductBreakdown";
import Profile from "./pages/Profile";
import Nav from "./components/Nav";
import GalleryWallBuilder from "./components/GalleryWallBuilder";
import { Margin } from "./components/Layout";
import { useAppState } from "./state";
import { adminClient, storefrontClient } from "./helpers/apolloClients";
import { ProfileStateProvider } from "./state/profile";

const ArtistApp = () => {
  const { navHeight, galleryWallBuilderIsOpen, toggleGalleryWallBuilderIsOpen } = useAppState();
  return (
    <div>
      <ApolloProvider client={storefrontClient}>
        <Nav />
        {galleryWallBuilderIsOpen && (
          <GalleryWallBuilder drawerIsOpen={galleryWallBuilderIsOpen} toggleDrawerIsOpen={toggleGalleryWallBuilderIsOpen} />
        )}
      </ApolloProvider>
      <Margin margin={navHeight} />
      <div style={{ minHeight: `calc(100vh - ${navHeight}px)` }}>
        <ApolloProvider client={adminClient}>
          <ProfileStateProvider>
            <Routes>
              <Route path="/dashboard/:id/edit" element={<AuthGuard element={EditProduct} />} />
              <Route path="/dashboard/:id" element={<AuthGuard element={ProductBreakdown} />} />
              <Route path="/" element={<AuthGuard element={Profile} />} />
            </Routes>
          </ProfileStateProvider>
        </ApolloProvider>
      </div>
    </div>
  );
};

export default ArtistApp;
