import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { GiftCollectionFragment, useGetCollectionsByTypeLazyQuery } from "../../generated/storefront";

enum CollectionType {
  Featured = "featured",
  Personality = "personality",
  Zodiac = "zodiac",
  GiftFor = "gift for",
  Room = "by room",
  Season = "season",
  Occasion = "occasion",
}

const useGiftGuideCollections = () => {
  const [getCollections] = useGetCollectionsByTypeLazyQuery();
  const [personalityCollections, setPersonalityCollections] = useState<GiftCollectionFragment[]>();
  const [loadingPersonalityCollections, setLoadingPersonalityCollections] = useState(false);
  const [zodiacCollections, setZodiacCollections] = useState<GiftCollectionFragment[]>();
  const [loadingZodiacCollections, setLoadingZodiacCollections] = useState(false);
  const [giftForCollections, setGiftForCollections] = useState<GiftCollectionFragment[]>();
  const [loadingGiftForCollections, setLoadingGiftForCollections] = useState(false);
  const [roomCollections, setRoomCollections] = useState<GiftCollectionFragment[]>();
  const [loadingRoomCollections, setLoadingRoomCollections] = useState(false);
  const [seasonCollections, setSeasonCollections] = useState<GiftCollectionFragment[]>();
  const [loadingSeasonCollections, setLoadingSeasonCollections] = useState(false);
  const [occasionCollections, setOccasionCollections] = useState<GiftCollectionFragment[]>();
  const [loadingOccasionCollections, setLoadingOccasionCollections] = useState(false);

  const getPersonalityCollections = useCallback(async () => {
    setLoadingPersonalityCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.Personality}`,
      },
    });

    setPersonalityCollections(data?.collections.nodes || []);
    setLoadingPersonalityCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!personalityCollections && !loadingPersonalityCollections) {
      getPersonalityCollections();
    }
  }, [personalityCollections, getPersonalityCollections, loadingPersonalityCollections]);

  const getZodiacCollections = useCallback(async () => {
    const zodiacOrder = [
      "capricorn",
      "aquarius",
      "pisces",
      "aries",
      "taurus",
      "gemini",
      "cancer",
      "leo",
      "virgo",
      "libra",
      "scorpio",
      "sagittarius",
    ];
    const currentZodiac = format(new Date(), "M").toLowerCase();
    const zodiacIndex = currentZodiac === "0" ? 11 : parseInt(currentZodiac) - 1;
    zodiacOrder.push(...zodiacOrder.splice(0, zodiacIndex));
    setLoadingZodiacCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.Zodiac}`,
      },
    });
    const zodiacCollections = data?.collections.nodes || [];
    const orderedZodiacCollections = zodiacCollections.sort((a, b) => {
      const aTitle = a.title.split(": ").pop()?.toLowerCase();
      const bTitle = b.title.split(": ").pop()?.toLowerCase();
      if (!aTitle || !bTitle) return 0;
      return zodiacOrder.indexOf(aTitle) - zodiacOrder.indexOf(bTitle);
    });
    setZodiacCollections(orderedZodiacCollections);
    setLoadingZodiacCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!zodiacCollections && !loadingZodiacCollections) {
      getZodiacCollections();
    }
  }, [zodiacCollections, getZodiacCollections, loadingZodiacCollections]);

  const getGiftForCollections = useCallback(async () => {
    setLoadingGiftForCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.GiftFor}`,
      },
    });
    setGiftForCollections(data?.collections.nodes || []);
    setLoadingGiftForCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!giftForCollections && !loadingGiftForCollections) {
      getGiftForCollections();
    }
  }, [giftForCollections, getGiftForCollections, loadingGiftForCollections]);

  const getRoomCollections = useCallback(async () => {
    setLoadingRoomCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.Room}`,
      },
    });

    setRoomCollections(data?.collections.nodes || []);
    setLoadingRoomCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!roomCollections && !loadingRoomCollections) {
      getRoomCollections();
    }
  }, [roomCollections, getRoomCollections, loadingRoomCollections]);

  const getSeasonCollections = useCallback(async () => {
    setLoadingSeasonCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.Season}`,
      },
    });

    setSeasonCollections(data?.collections.nodes || []);
    setLoadingSeasonCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!seasonCollections && !loadingSeasonCollections) {
      getSeasonCollections();
    }
  }, [seasonCollections, getSeasonCollections, loadingSeasonCollections]);

  const getOccasionCollections = useCallback(async () => {
    setLoadingOccasionCollections(true);
    const { data } = await getCollections({
      variables: {
        type: `title:${CollectionType.Occasion}`,
      },
    });

    setOccasionCollections(data?.collections.nodes || []);
    setLoadingOccasionCollections(false);
  }, [getCollections]);

  useEffect(() => {
    if (!occasionCollections && !loadingOccasionCollections) {
      getOccasionCollections();
    }
  }, [occasionCollections, getOccasionCollections, loadingOccasionCollections]);

  return {
    personalityCollections,
    loadingPersonalityCollections,
    zodiacCollections,
    loadingZodiacCollections,
    giftForCollections,
    loadingGiftForCollections,
    roomCollections,
    loadingRoomCollections,
    seasonCollections,
    loadingSeasonCollections,
    occasionCollections,
    loadingOccasionCollections,
  };
};

export default useGiftGuideCollections;
