import { LazyLoadImage as LazyLoadImageComp } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type Props = {
  src: string;
  alt: string;
  height?: number | string;
  width?: number | string;
  style?: any;
  onLoad?: () => void;
};

const LazyLoadImage = (props: Props) => <LazyLoadImageComp {...props} effect="blur" threshold={2000} />;

export default LazyLoadImage;
