import { match } from "ts-pattern";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  FmdGoodOutlined,
  LocalShippingOutlined,
  PaymentRounded,
  PublicRounded,
  StarBorderRounded,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { colors } from "../../theme";
import { useAppState } from "../../state";
import { CountryCode } from "../../generated/storefront";
import { Icon } from "../../components/Icon";

const Cards = () => {
  const { selectedCountry } = useAppState();

  const handmadeCopy = match(selectedCountry)
    .with(CountryCode.Us, () => "Handmade in USA")
    .with(CountryCode.Gb, () => "Handmade in UK")
    .with(CountryCode.Au, () => "Handmade in AUS")
    .with(CountryCode.At, () => "Handmade in EU")
    .otherwise(() => "Handmade frames");

  const handmadeIcon = match(selectedCountry)
    .with(CountryCode.Us, () => <Icon icon="usaFlag" size={26} />)
    .with(CountryCode.Gb, () => <Icon icon="ukFlag" size={26} />)
    .with(CountryCode.Au, () => <Icon icon="ausFlag" size={26} />)
    .with(CountryCode.At, () => <Icon icon="euFlag" size={26} />)
    .otherwise(() => <FmdGoodOutlined />);

  const cards = [
    {
      icon: <LocalShippingOutlined />,
      text: "30 day returns",
    },
    {
      icon: <StarBorderRounded />,
      text: "Ready to hang",
    },
    {
      icon: handmadeIcon,
      text: handmadeCopy,
    },
    {
      icon: <WorkspacePremiumOutlined />,
      text: "Museum quality",
    },
    {
      icon: <PaymentRounded />,
      text: "Secure transactions",
    },
    {
      icon: <PublicRounded />,
      text: "Planet conscious",
    },
  ];

  return (
    <Grid container spacing={0.5}>
      {cards.map((card) => (
        <Grid item xs={6} md={4}>
          <Box bgcolor={colors.grey02} padding={{ xs: 1, md: 1.5 }} height="100%">
            <Stack gap={{ xs: 0.3, md: 0.5 }} justifyContent="center" alignItems="center">
              {card.icon}
              <Typography variant="caption" fontSize={12} align="center">
                {card.text}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default Cards;
