import { CircularProgress, FilledInput, InputAdornment, Typography } from "@mui/material";
import { DoneRounded, EastRounded } from "@mui/icons-material";
import { Form } from "../Layout";
import { colors } from "../../theme";
import { useState } from "react";
import { addContact } from "../../services/API";
import { useAppState } from "../../state";

const NewsletterInput = () => {
  const { setCustomerEmail } = useAppState();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);

  const onInputChange = (event: any) => {
    setError(false);
    setEmail(event.target.value);
  };

  const onSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    try {
      await addContact({ email, subscribe: true });
      setCustomerEmail(email);
      setLoading(false);
      setAdded(true);
      setTimeout(() => setAdded(false), 3000);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Typography color={colors.white}>Subscribe to our newsletter for a mystery discount</Typography>
      <FilledInput
        name="newsletter"
        id="newsletter"
        hiddenLabel
        fullWidth
        error={error}
        onChange={onInputChange}
        placeholder="Email address"
        style={{
          color: colors.grey40,
          backgroundColor: colors.grey90,
          padding: "8px 32px 8px 16px",
        }}
        sx={{
          "&:-internal-autofill-selected": { backgroundColor: "transparent !important" },
          "&:-webkit-autofill": { backgroundColor: "transparent !important" },
        }}
        endAdornment={
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={20} sx={{ width: 10, color: colors.white }} />
            ) : added ? (
              <DoneRounded fontSize="small" sx={{ color: colors.white }} />
            ) : (
              <EastRounded fontSize="small" sx={{ color: colors.white, cursor: "pointer" }} onClick={onSubmit} />
            )}
          </InputAdornment>
        }
      />
      {error && (
        <Typography color={colors.white} fontSize={12}>
          Cannot subscribe email, please try again
        </Typography>
      )}
    </Form>
  );
};

export default NewsletterInput;
