import { Link } from "react-router-dom";
import { Chip, Stack, Typography } from "@mui/material";
import { AccessAlarm } from "@mui/icons-material";
import Modal from "../Modal";
import { colors } from "../../theme";
import { Form } from "../Layout";
import Input from "../Form/Input";
import Button from "../Button";
import { useState } from "react";
import { addContact } from "../../services/API";
import { getThisMonth } from "../../helpers/time";
import { useAppState } from "../../state";

const NewsletterModal = ({ setModalOpen }: { setModalOpen: (open: boolean) => void }) => {
  const { setCustomerEmail } = useAppState();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  const onInputChange = (event: any) => {
    setError(false);
    setEmail(event.target.value);
  };

  const onSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    try {
      await addContact({ email, subscribe: true });
      setCustomerEmail(email);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  // const onCopy = async () => {
  //   try {
  //     await navigator.clipboard.writeText("GM15");
  //     setCopied(true);
  //     setTimeout(() => setModalOpen(false), 2000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Modal size="small" onClose={() => setModalOpen(false)}>
      {success ? (
        <Stack gap={3} alignItems="center">
          <Stack gap={1} alignItems="center">
            <Typography variant="h2" fontSize={{ xs: 32, md: 44 }}>
              Sent!
            </Typography>
            <Typography variant="body2" color={colors.grey60} align="center">
              Check your email to discover your mystery discount code
            </Typography>
          </Stack>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button onClick={() => setModalOpen(false)} loading={loading} size="large" fullWidth>
              Shop now
              {/* <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              {copied ? (
                <>
                  Copied <Check />
                </>
              ) : (
                <>
                  Copy <ContentCopy fontSize="small" />
                </>
              )}
            </Stack> */}
            </Button>
          </Link>
        </Stack>
      ) : (
        <Stack gap={2} alignItems="center">
          <Stack gap={1} alignItems="center">
            <Chip
              label={
                <Typography component="div">
                  <Stack gap={0.5} direction="row" alignItems="center">
                    <AccessAlarm fontSize="inherit" />
                    <Typography variant="body2" color={colors.grey90} fontSize={{ xs: 12 }} fontWeight={500}>
                      VALID FOR {getThisMonth().toUpperCase()} ONLY!
                    </Typography>
                  </Stack>
                </Typography>
              }
              color="success"
              size="small"
            />
            <Typography variant="h2" align="center" fontSize={{ xs: 26, md: 40 }}>
              MYSTERY DISCOUNT?
            </Typography>
            <Typography variant="body2" color={colors.grey60}>
              Enter your email below to recieve a code
            </Typography>
          </Stack>
          <Form>
            <Input name="email" id="email" onChange={onInputChange} placeholder="Email address" autoFocus />
            <Button onClick={onSubmit} loading={loading} size="large">
              Send
            </Button>

            {error && (
              <Typography color="error" margin="4px 0" fontSize={14} align="center">
                Cannot subsribe email, please try again
              </Typography>
            )}
          </Form>
        </Stack>
      )}
    </Modal>
  );
};

export default NewsletterModal;
