import styled from "styled-components";
import { Stack } from "@mui/material";
import mockRoom from "../../assets/images/mockRoom.jpg";
import mockWall from "../../assets/images/mockWall.jpg";
import { Container, Flex } from "../Layout";
import { Frame, Size, TShirtSize } from "../../types/product";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import DynamicFrameMockUp from "../FrameMockUp/DynamicFrameMockUp";
import FrameMockUp from "../FrameMockUp";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  frame: Frame;
  size: Size;
  mounted?: boolean;
};

type MockUpProps = {
  frame?: Frame;
  size: Size;
  mounted?: boolean;
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  isMobile: boolean;
};

const Background = styled.img`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductImage = styled.div`
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const PlainMockUp = ({
  frame = Frame.Unframed,
  mounted = false,
  image,
  isMobile,
}: Omit<MockUpProps, "size"> & { size?: Size }) => {
  return (
    <Container
      margin={isMobile ? "0" : "0 0 4px"}
      width={100}
      style={{ height: isMobile ? "100%" : "80vh" }}
      background={colors.grey05}
      padding={isMobile ? "0 20px 16px" : "20px"}
    >
      <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
        {isMobile ? (
          <DynamicFrameMockUp frame={frame} mounted={mounted} image={image} scale={isMobile ? window.outerHeight / 400 : 1.6} />
        ) : (
          <FrameMockUp frame={frame} mounted={mounted} image={image} tShirtSize={TShirtSize.Large} resolution={800} contain />
        )}
      </Stack>
    </Container>
  );
};

export const RoomMockUp = ({ frame = Frame.Natural, size, mounted = false, image, isMobile }: MockUpProps) => {
  if (!image.height || !image.width) return null;
  const isLandscape = image.height < image.width;
  const style = isLandscape ? { left: "47%" } : {};
  return (
    <Container margin={isMobile ? "0" : "0 0 4px"} width={100} style={{ height: isMobile ? "100%" : "700px" }}>
      <Flex justifyContent="center" style={{ height: "100%" }}>
        <Background src={mockRoom} alt="mock room" />
        <ProductImage style={style}>
          <DynamicFrameMockUp frame={frame} mounted={mounted} image={image} size={size} shadow={frame !== Frame.Unframed} />
        </ProductImage>
      </Flex>
    </Container>
  );
};

export const WallMockUp = ({ frame = Frame.Natural, size, mounted = false, image, isMobile }: MockUpProps) => {
  if (!image.height || !image.width) return null;
  const isLandscape = image.height < image.width;
  const style = image.height === image.width ? { top: "36%" } : isLandscape ? { top: "36%", left: "54%" } : {};
  return (
    <Container margin={isMobile ? "0" : "0 0 4px"} width={100} style={{ height: isMobile ? "100%" : "700px" }}>
      <Flex justifyContent="center" style={{ height: "100%" }}>
        <Background src={mockWall} alt="mock wall" />
        <ProductImage style={style}>
          <DynamicFrameMockUp frame={frame} mounted={mounted} image={image} size={size} shadow={frame !== Frame.Unframed} />
        </ProductImage>
      </Flex>
    </Container>
  );
};

const MockUps = ({ image, frame, size, mounted = false }: Props) => {
  const appState = useAppState();
  const { isMobileScreen } = appState;

  return (
    <>
      <PlainMockUp image={image} frame={frame} size={size} mounted={mounted} isMobile={isMobileScreen} />
      <RoomMockUp image={image} frame={frame} size={size} mounted={mounted} isMobile={isMobileScreen} />
      <WallMockUp image={image} frame={frame} size={size} mounted={mounted} isMobile={isMobileScreen} />
    </>
  );
};

export default MockUps;
