import { Fragment } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
// @ts-ignore
import { Markup } from "react-render-markup";
import Accordion from "../../components/Accordion";
import blackFrameChevron from "../../assets/images/blackFrameChevron.png";
import whiteFrameChevron from "../../assets/images/whiteFrameChevron.png";
import placeholder from "../../assets/images/placeholder.png";
import naturalFrameChevron from "../../assets/images/naturalFrameChevron.png";
import { Text } from "../../components/Text";
import { Flex, Grid, GridItem } from "../../components/Layout";
import { Image as ImageComp } from "../../components";
import TextLink from "../../components/TextLink";
import { User } from "../../types/user";
import { useAppState } from "../../state";
import { getAvailableSizes, shippinngCostsForCountry, sizeLabelsForCountry } from "../../helpers/product";
import { ProductPageProductFragment } from "../../generated/storefront";

type Props = {
  product: ProductPageProductFragment;
  artist?: User | null;
  setItemIsOpen: (isOpen: boolean) => void;
};

const PaperGrid = styled(Grid)`
  margin-left: -8px;

  > div {
    padding: 4px 8px;
  }
`;

const InfoAccordion = ({ product, artist, setItemIsOpen }: Props) => {
  const { selectedCountry } = useAppState();
  const availabileSizes = getAvailableSizes(product.variants.nodes);
  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const shippingCosts = shippinngCostsForCountry(selectedCountry);

  const checkOpenItems = () => {
    const openItems = document.querySelector(".open");
    setItemIsOpen(Boolean(openItems));
  };

  return (
    <Accordion
      onItemOpen={checkOpenItems}
      items={[
        ...(product.descriptionHtml
          ? [
              {
                label: "About the piece",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Typography fontSize={14}>
                      <Markup markup={product.descriptionHtml} style={{ margin: 0 }} />
                    </Typography>
                  </Stack>
                ),
              },
            ]
          : []),
        {
          label: "Delivery and returns",
          content: (
            <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
              <Text>
                We typically deliver within 5 working days in the UK, EU, US & Australia, with no import duties or taxes. For more
                details and further information on ordering to other countries, please read our{" "}
                <TextLink to="/delivery" openInNewTab>
                  delivery policy
                </TextLink>
                .
              </Text>
              <Text>
                In the UK, EU, US & Australia we charge {shippingCosts.unframed} for an unframed print and {shippingCosts.framed}{" "}
                for a framed print. We charge more for other destinations, depending where you are purchasing.
              </Text>
              <Text>
                We offer returns within 30 days of delivery, for more info please read our{" "}
                <TextLink to="/delivery" openInNewTab>
                  returns policy
                </TextLink>
                .
              </Text>
              <Text>
                In the unlikely event of an item being delivered damaged or faulty, please contact us on{" "}
                <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> and we will resolve it
                immedietely.
              </Text>
            </Stack>
          ),
        },

        ...[
          ...(artist
            ? [
                {
                  label: "About the artist",
                  content: (
                    <Stack direction="row" gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4} alignItems="center">
                      <div>
                        <ImageComp src={artist.image || placeholder} radius="100%" height="100px" width="100px" />
                      </div>
                      <Stack gap={1} alignItems={artist.about ? "normal" : "center"}>
                        <TextLink to={`/artists/${artist.permalink || artist.id}`}>
                          <>
                            {artist.firstName} {artist.lastName}
                          </>
                        </TextLink>
                        <Text>{artist.about}</Text>
                      </Stack>
                    </Stack>
                  ),
                },
              ]
            : []),
          {
            label: "Specifications",
            content: (
              <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                <Text>
                  We print using industry leading Giclée printers and archival grade 200gsm matte paper, printing in up to
                  individual 12 colours leading to museum level quality prints.
                </Text>
                <Flex gap={24}>
                  <PaperGrid columns={2}>
                    <GridItem span={2} outline={false}>
                      <Text size={14}>Paper sizes</Text>
                    </GridItem>
                    {availabileSizes.map((size) => (
                      <Fragment key={size}>
                        <GridItem outline={false}>
                          <Text size={14}>{sizeLabels[size].shortHand}</Text>
                        </GridItem>
                        <GridItem outline={false}>
                          <Text size={14}>{sizeLabels[size].printMeasurements}</Text>
                        </GridItem>
                      </Fragment>
                    ))}
                  </PaperGrid>

                  <PaperGrid columns={2}>
                    <GridItem span={2} outline={false}>
                      <Text size={14}>Framed print sizes</Text>
                    </GridItem>
                    {availabileSizes.map((size) => (
                      <Fragment key={size}>
                        <GridItem outline={false}>
                          <Text size={14}>{sizeLabels[size].shortHand}</Text>
                        </GridItem>
                        <GridItem outline={false}>
                          <Text size={14}>{sizeLabels[size].framedMeasurements}</Text>
                        </GridItem>
                      </Fragment>
                    ))}
                  </PaperGrid>
                </Flex>
                <Text>
                  Our frames are created from high-quality wood, milled with simple clean lines and presented with a satin finish.
                  They have a perspex glaze offering great sunlight resistance, protecting your print.
                </Text>
                <ul style={{ margin: 0, paddingLeft: "15px" }}>
                  <li>
                    <Text>
                      Arrives ready to hang (view hanging guide <TextLink to="/hanging-guide">here</TextLink>)
                    </Text>
                  </li>
                  <li>
                    <Text>Profile: 2cm (0.8") x 2.3cm (0.9") (front face x depth from wall)</Text>
                  </li>
                  <li>
                    <Text>Handcrafted from sustainably sourced wood</Text>
                  </li>
                  <li>
                    <Text>Satin laminated finish for added protection</Text>
                  </li>
                  <li>
                    <Text>
                      Mounting: adds a 2 inch, off white mount (2.4mm thick) around the print, reducing the size of the print by 2
                      inches on all sides
                    </Text>
                  </li>
                </ul>
                <Flex>
                  <img src={blackFrameChevron} alt="black frame chevron" width={`${100 / 3}%`} />
                  <img src={whiteFrameChevron} alt="white frame chevron" width={`${100 / 3}%`} />
                  <img src={naturalFrameChevron} alt="natural frame chevron" width={`${100 / 3}%`} />
                </Flex>
              </Stack>
            ),
          },
        ],
      ]}
    />
  );
};

export default InfoAccordion;
