import { HTMLAttributes, ReactChild } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export type Props = {
  href?: string;
  to?:
    | string
    | {
        pathname: string;
        state: any;
      };
  openInNewTab?: boolean;
  children: ReactChild;
} & HTMLAttributes<HTMLAnchorElement>;

const StyledLink = styled(Link)`
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  white-space: nowrap;
`;

const StyledA = styled.a`
  text-decoration: underline;
  text-underline-offset: 4px;
  word-break: break-all;
  cursor: pointer;
  white-space: nowrap;
`;

const StyledSpan = styled.span`
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  white-space: nowrap;
`;

const TextLink = ({ href, openInNewTab, to, children, onClick, ...props }: Props) => {
  const rel = openInNewTab ? "noopener noreferrer" : "";
  const target = openInNewTab ? "_blank" : "";
  if (to) {
    return (
      <StyledLink to={to} target={target} rel={rel} {...props}>
        {children}
      </StyledLink>
    );
  }
  if (href) {
    return (
      <StyledA href={href} target={target} rel={rel} {...props}>
        {children}
      </StyledA>
    );
  }
  if (onClick) {
    return (
      <StyledSpan onClick={onClick} {...props}>
        {children}
      </StyledSpan>
    );
  }
  return <StyledSpan {...props}>{children}</StyledSpan>;
};

export default TextLink;
