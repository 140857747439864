import styled from "styled-components";

export const Image = styled.img<{ width?: string; height?: string; radius?: string; objectFit?: string }>`
  width: ${(p) => (p.width ? `${p.width}` : "100%")};
  height: ${(p) => (p.height ? `${p.height}` : "100%")};
  ${(p) => p.radius && `border-radius: ${p.radius}`};
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: ${(p) => p.objectFit || "cover"};
`;
