import { uniqueId } from "lodash";
import { Box, Grid, Stack } from "@mui/material";
import CollectionCard from "../../../Card/Collection";
import { Loader } from "../../../Loader";
import { useGetGalleryWallCollectionsQuery } from "../../../../generated/storefront";

type Props = {
  isMobile?: boolean;
};

const scrollbarStyles = {
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const GalleryWallsSearchTab = ({ isMobile = false }: Props) => {
  const { data } = useGetGalleryWallCollectionsQuery({
    variables: {
      limit: 9,
    },
  });
  const collections = data?.collections?.nodes;

  return (
    <Box>
      {collections ? (
        isMobile ? (
          <Stack gap={1} direction="row" overflow="auto" sx={scrollbarStyles}>
            {collections.map((collection) => (
              <Stack flex="0 0 35%" width="35%">
                <CollectionCard collection={collection} imageHeight="30vw" borderRadius={12} size="small" />
              </Stack>
            ))}
          </Stack>
        ) : (
          <Grid container spacing={1} rowSpacing={2}>
            {collections.map((collection) => (
              <Grid item xs={12 / 3} key={uniqueId()}>
                <CollectionCard collection={collection} imageHeight="150px" borderRadius={12} size="small" />
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default GalleryWallsSearchTab;
