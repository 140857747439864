import WebpImage from "react-image-webp";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Frame, Ratio, RectangleSize, Size, TShirtSize } from "../../types/product";
import { mmDimensions, mountingWidth } from "../../helpers/layout";
import { tShirtSizeToSize } from "../../helpers/product";
import { getFrameImages } from "./helpers";
import { Wrapper } from "./styles";
import LazyLoadImage from "../Image/LazyLoadImage";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  frame: Frame;
  tShirtSize?: TShirtSize;
  size?: Size;
  resolution?: number;
  contain?: boolean;
  mounted?: boolean;
  imageLoaded?: boolean;
  setImageLoaded?: (loaded: boolean) => void;
};

const FrameImage = styled(WebpImage)<{ unframed?: boolean }>`
  z-index: 1;
  object-fit: contain;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  ${(p) => p.unframed && `visibility: hidden;`}
`;

const FrameMockUp = ({
  image,
  frame,
  tShirtSize = TShirtSize.Medium,
  size,
  resolution = 500,
  contain = false,
  mounted = false,
  imageLoaded = true,
  setImageLoaded,
}: Props) => {
  if (!image) return null;
  if (!image.width || !image.height) return null;
  const imageRatio = image.width / image.height;
  const ratio = imageRatio === 1 ? Ratio.Square : Ratio.Rectangle;
  const isLandscape = image.height < image.width;
  const frameImages = getFrameImages(ratio, frame, tShirtSize, isLandscape);
  const fixedSize = size || tShirtSizeToSize(tShirtSize, ratio);
  const sizetoCalcMountingPct = match(fixedSize)
    .with(RectangleSize.A3, () => RectangleSize.A2)
    .otherwise(() => fixedSize);
  const mountingWidthPct = (mountingWidth / mmDimensions[sizetoCalcMountingPct][isLandscape ? "height" : "width"]) * 100;
  const mountingHeightPct = (mountingWidth / mmDimensions[sizetoCalcMountingPct][isLandscape ? "width" : "height"]) * 100;
  const shadowWidth = mountingWidthPct / 5;

  let widthPct = 100;
  let heightPct = 100;
  if (frame !== Frame.Unframed) {
    if (isLandscape) {
      widthPct -= 3;
      heightPct -= 4;
    } else if (ratio === Ratio.Square) {
      widthPct -= 3;
      heightPct -= 3;
    } else {
      widthPct -= 4;
      heightPct -= 3;
    }
  }
  if (mounted) {
    widthPct -= mountingWidthPct * 2;
    heightPct -= mountingHeightPct * 2;
  }

  const imageStyles = {
    width: `${widthPct}%`,
    height: `${heightPct}%`,
    position: "absolute" as const,
    objectFit: mounted ? ("fill" as const) : ("contain" as const),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow:
      !mounted || frame === Frame.Unframed || !imageLoaded
        ? "none"
        : `inset 0 0 0 10px #F9F9F9, 0 0 0 ${shadowWidth}px rgba(0, 0, 0, 0.1)`,
  };
  const height = isLandscape ? 70 : 90;
  const style = contain ? { width: "auto", height: `${height}%`, maxWidth: "90%" } : { width: "100%", height: "fit-content" };
  const resolutionParam = isLandscape ? `&height=${resolution}` : `&width=${resolution}`;

  return (
    <Wrapper style={style} imageLoaded={imageLoaded} mounted={mounted} className="frame-mock-up">
      {imageLoaded && (
        <FrameImage src={frameImages.src} webp={frameImages.webp} alt="frame" unframed={frame === Frame.Unframed} />
      )}
      <LazyLoadImage
        src={image.src + resolutionParam}
        alt="print"
        style={imageStyles}
        onLoad={() => setImageLoaded && setImageLoaded(true)}
      />
    </Wrapper>
  );
};

export default FrameMockUp;
